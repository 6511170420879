import { Controller } from "stimulus";
import React from "react";
import ReactDOM from "react-dom";
import debounce from "lodash.debounce";

import Modal from "../lib/modal";
import ActionSuggestionModal from "../components/ActionSuggestionModal";

export default class extends Controller {
  static targets = ["input", "results", "selectable", "info", "infoText"];
  static values = {
    index: { type: Number, default: -1 },
    formScope: { type: String, default: "" },
  };

  initialize() {
    this.search = debounce(this.search, 300).bind(this);
    this.modal = new Modal();
  }

  connect() {
    document.addEventListener("keydown", this.onKeyDown);
    document.addEventListener("mousedown", this.preventBlurOnDropdownClick);
  }

  disconnect() {
    document.removeEventListener("keydown", this.onKeyDown);
    document.removeEventListener("mousedown", this.preventBlurOnDropdownClick);
  }

  preventBlurOnDropdownClick = (e) => {
    // Prevent clicks within the result area from blurring the input field,
    // which closes the results and any click on a result row will not be
    // triggered.
    if (this.resultsTarget.contains(e.target)) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  onKeyDown = (e) => {
    if (
      this.resultsTarget.classList.contains("hidden") &&
      !this.modal.isVisible
    ) {
      return;
    }

    if (e.key === "ArrowDown") {
      this.changeIndexBy(1);
      e.preventDefault();
    } else if (e.key === "ArrowUp") {
      this.changeIndexBy(-1);
      e.preventDefault();
    } else if (e.key === "Escape") {
      if (this.modal.isVisible) {
        this.hideModal();
      } else {
        this.hideResults();
      }
    } else if (e.key === "Enter") {
      this.triggerSelectedItem(e);
      this.hideResults();
      this.inputTarget.blur();
    }
  };

  changeIndexBy(delta) {
    let newIndex = this.indexValue + delta;
    if (newIndex >= this.selectableTargets.length) {
      newIndex = -1;
    } else if (newIndex < -1) {
      newIndex = this.selectableTargets.length - 1;
    }

    this.indexValue = newIndex;
  }

  indexValueChanged() {
    this.selectableTargets.forEach((el, index) => {
      if (index !== this.indexValue) {
        el.classList.remove("active");
      } else {
        el.classList.add("active");
      }
    });
  }

  triggerSelectedItem(e) {
    if (this.indexValue == -1) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    this.selectableTargets[this.indexValue].click();
  }

  showModal = (e) => {
    const includeFilter = e?.params?.useQuery;
    ReactDOM.render(
      this.getReactComponent(includeFilter),
      this.getReactRootNode()
    );
    this.modal.show();
  };

  hideModal = (resetFocus = true) => {
    if (!this.modal.isVisible) {
      return;
    }

    this.modal.hide();
    ReactDOM.unmountComponentAtNode(this.getReactRootNode());

    if (resetFocus) {
      this.inputTarget.focus();
    }
  };

  getReactRootNode() {
    return this.modal.getModalElement().querySelector(".modal-content");
  }

  async search() {
    if (this.inputTarget !== document.activeElement) {
      return;
    }

    const response = await fetch(
      "/actions/autocomplete?query=" +
        encodeURIComponent(this.inputTarget.value),
      { redirect: "manual" }
    );
    if (response.status === 200) {
      const results = await response.text();
      this.resultsTarget.innerHTML = results;
    } else {
      this.resultsTarget.innerHTML =
        "Någonting gick fel! Ladda om sidan och försök igen.";
    }
    this.showResults();
  }

  select(e) {
    this.onSelect(e.params.item);
  }

  onSelect = (item) => {
    this.hideModal(false);
    this.hideResults();

    const form = document.querySelector(this.formScopeValue);
    const fieldValues = Object.assign({}, item, {
      maintenance_plan_action_suggestion_id: item.id,
      indexation_starts_on_year: new Date().getFullYear(),
    });

    form.querySelector(
      "#connected_suggestion_name"
    ).innerText = `”${fieldValues.name}”`;

    [
      "name",
      "unit",
      "unit_price",
      "repeated",
      "maintenance_plan_action_suggestion_id",
      "indexation_starts_on_year",
    ].forEach(function (key) {
      const element = form.querySelector(
        `[name="maintenance_plan_action[${key}]"]`
      );
      element.value = fieldValues[key];
      element.dispatchEvent(new Event("change"));
    });

    const text = fieldValues["info"] || "";
    this.showInfoText(text !== "");
    this.updateInfoText(text);
  };

  showInfoText(visible) {
    this.infoTarget.hidden = !visible;
  }

  updateInfoText(text) {
    this.infoTextTarget.innerHTML = "";
    text
      .split(/(\r\n|\r){2,}/)
      .map((paragraph) => paragraph.trim())
      .filter((paragraph) => paragraph !== "")
      .forEach((paragraph) => {
        const pElement = document.createElement("p");
        paragraph.split(/\r\n|\r/).forEach((line, index, array) => {
          pElement.appendChild(document.createTextNode(line));
          if (index < array.length - 1) {
            pElement.appendChild(document.createElement("br"));
          }
        });
        this.infoTextTarget.appendChild(pElement);
      });
  }

  showResults() {
    if (this.inputTarget !== document.activeElement) {
      return;
    }
    this.resultsTarget.classList.remove("hidden");
  }

  hideResults() {
    this.resultsTarget.classList.add("hidden");
  }

  getReactComponent = (includeFilter) => {
    return React.createElement(ActionSuggestionModal, {
      onSelect: (item) => {
        this.onSelect(
          Object.assign(
            {
              id: item.id,
            },
            item.attributes
          )
        );
      },
      onClose: this.hideModal,
      initialFilter: includeFilter ? this.inputTarget.value : null,
    });
  };
}
