import { Controller } from "stimulus";
import TomSelect from "tom-select";
import { defaultConfigTags } from "../config/tom-select";

export default class extends Controller {
  static targets = ["controlInput", "dropdownParent"];

  connect() {
    this.customDropdown = new TomSelect(
      this.element.querySelector("select"),
      this.config()
    );
  }

  config() {
    return Object.assign({}, defaultConfigTags, {
      controlInput: this.controlInputTarget,
      dropdownParent: this.dropdownParentTarget,
    });
  }

  disconnect() {
    this.customDropdown.destroy();
  }
}
