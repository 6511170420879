/*global $ */

document.addEventListener("DOMContentLoaded", () => {
  const sortWithIntl = () => {
    $.fn.dataTable.ext.order.intl = function (locales, options) {
      if (window.Intl) {
        var collator = new window.Intl.Collator(locales, options);
        var types = $.fn.dataTable.ext.type;

        delete types.order["string-pre"];
        types.order["string-asc"] = collator.compare;
        types.order["string-desc"] = function (a, b) {
          return collator.compare(a, b) * -1;
        };
      }
    };

    $.fn.dataTable.ext.order.intl("sv");
  };

  const initDataTables = () => {
    $(".dataTable").dataTable({
      oLanguage: dtSwedish,
      bSearch: true,
      bInfo: true,
      bFilter: true,
      bLengthChange: true,
      stateSave: true,
    });

    $(".dataTable-simple").dataTable({
      oLanguage: dtSwedish,
      sDom: "flrtip",
      bSearch: true,
      bInfo: true,
      bFilter: true,
      iDisplayLength: 10,
      bLengthChange: false,
      stateSave: false,
      initComplete: updateSearchBarLayout,
    });
  };

  const updateSearchBarLayout = function (settings) {
    const wrapper = settings.nTableWrapper;
    const table = wrapper.querySelector("table");

    // Fix styling of search input
    wrapper.classList.remove("form-inline");
    const input = wrapper.querySelector("input.form-control");

    const inputGroup = document.createElement("div");

    inputGroup.className = "input-group col-lg-6 col-md-6 col-sm-12";
    const inputGroupAddon = document.createElement("div");
    inputGroupAddon.classList.add("input-group-addon");
    const icon = document.createElement("i");
    icon.classList.add("fa");
    icon.classList.add("fa-search");
    inputGroupAddon.appendChild(icon);
    inputGroup.appendChild(inputGroupAddon);

    inputGroup.appendChild(input);
    input.placeholder = "Sök...";

    wrapper.querySelector(".dataTables_filter").appendChild(inputGroup);
    const label = wrapper.querySelector(".dataTables_filter label");
    wrapper.querySelector(".dataTables_filter").removeChild(label);

    wrapper.querySelector(".dataTables_filter").classList.add("mb-16");
    const actionButtonWrapper = document.createElement("div");
    const actionButton = document.querySelector("#action_btn");
    if (table.dataset.hasAction == "true" && actionButton) {
      actionButtonWrapper.appendChild(actionButton);
      wrapper
        .querySelector(".dataTables_filter")
        .appendChild(actionButtonWrapper);
      wrapper
        .querySelector(".dataTables_filter")
        .classList.add("flex", "flex-jc-spb", "gap-24", "align-items-center");
    }

    // Fix styling of pagination and row info
    if (table.classList.contains("dataTable-simple")) {
      const footerWrapper = document.createElement("div");
      footerWrapper.style =
        "display: flex; justify-content: space-between; align-items: center;";
      footerWrapper.appendChild(wrapper.querySelector(".dataTables_info"));
      footerWrapper.appendChild(wrapper.querySelector(".dataTables_paginate"));
      wrapper.appendChild(footerWrapper);
    }

    // Set length if data attribute exists
    if (table.dataset.pageLength) {
      const api = $(table).DataTable();
      api.page.len(table.dataset.pageLength).draw();
    }
  };

  const dtSwedish = {
    sEmptyTable: "Tabellen innehåller ingen data",
    sInfo: "Visar _START_ till _END_ av totalt _TOTAL_ rader",
    sInfoEmpty: "Visar 0 till 0 av totalt 0 rader",
    sInfoFiltered: "(filtrerade från totalt _MAX_ rader)",
    sInfoPostFix: "",
    sInfoThousands: ",",
    sLengthMenu: "Visa _MENU_ rader",
    sLoadingRecords: "Laddar...",
    sProcessing: "Bearbetar...",
    sSearch: "Sök:",
    sZeroRecords: "Hittade inga matchande resultat",
    oPaginate: {
      sFirst: "Första",
      sLast: "Sista",
      sNext: "Nästa",
      sPrevious: "Föregående",
    },
    oAria: {
      sSortAscending: ": aktivera för att sortera kolumnen i stigande ordning",
      sSortDescending: ": aktivera för att sortera kolumnen i fallande ordning",
    },
  };

  sortWithIntl();
  initDataTables();
});
