const confirmDeleteInModal = () => {
  const modal = window.$("#modal-delete");
  if (!modal) {
    return;
  }

  modal.on("show.bs.modal", showModal);
  modal.on("hidden.bs.modal", hideModal);

  if (autoTriggerModal()) {
    const trigger = document.querySelector("[data-toggle='modal']");
    trigger?.click();
  }
};

window.addEventListener("DOMContentLoaded", confirmDeleteInModal);

const autoTriggerModal = () => {
  let url = new URL(window.location.href);
  return Boolean(url.searchParams.get("toggleDeleteModal"));
};

const showModal = (e) => {
  const currentResourceName = e.relatedTarget.dataset.resourceName;
  const maintenancePlansInformation =
    e.relatedTarget.dataset.maintenancePlansInformation;
  let confirmValue;
  if (e.relatedTarget.dataset.type == "mass_edit") {
    confirmValue = e.relatedTarget.dataset.confirmValue;
  } else {
    confirmValue = e.relatedTarget.dataset.resourceName;
  }

  // manipulate form action
  const form = e.target.querySelector("form");
  form.action = e.relatedTarget.dataset.url;

  // listen to the verify input to enable the submit button if the value
  // matches the resource name
  const verifiedInput = form.querySelector("[data-verified]");
  verifiedInput.addEventListener("input", enableSubmitOnVerified);

  // update information in modal
  updateNameOfResource(currentResourceName);
  updateConfirmValue(confirmValue);
  updateRelatedResourcesInformationForType(e.relatedTarget.dataset.type);
  updateMaintenancePlansInformation(maintenancePlansInformation);
};

const hideModal = (e) => {
  const verifiedInput = e.target.querySelector("[data-verified]");
  const submitButton = e.target.querySelector("[data-submit]");

  // The submit button needs to be disabled here since the event to toggle
  // the disabled state is not triggered when the modal is opened.
  submitButton.disabled = true;
  verifiedInput.value = "";

  verifiedInput.removeEventListener("keyup", enableSubmitOnVerified);
};

const enableSubmitOnVerified = () => {
  const submitButton = document.querySelector("#modal-delete [data-submit]");
  const verifiedInputValue = document
    .querySelector("#modal-delete [data-verified]")
    .value.toLowerCase();
  const confirmValue = document
    .querySelector("#modal-delete [data-confirm-value]")
    .innerText.toLowerCase();

  submitButton.disabled = verifiedInputValue != confirmValue;
};

const updateNameOfResource = (currentResourceName) => {
  document.querySelector(
    "#modal-delete [data-name]"
  ).textContent = currentResourceName;
};

const updateConfirmValue = (confirmValue) => {
  document.querySelector(
    "#modal-delete [data-confirm-value]"
  ).textContent = confirmValue;
};

const updateRelatedResourcesInformationForType = (type) => {
  document.querySelector(
    "#modal-delete [data-related-resources]"
  ).textContent = relatedResources(type);
};

const updateMaintenancePlansInformation = (maintenancePlansInformation) => {
  if (!maintenancePlansInformation) {
    return;
  }

  const maintenancePlans = document.querySelector(
    "#modal-delete [data-maintenance-plans]"
  );
  if (maintenancePlans) {
    maintenancePlans.textContent = maintenancePlansInformation;
  }
};

const relatedResources = (type) => {
  switch (type) {
    case "facility":
      return "den och dess underhållsplaner permanent raderade.";
    case "organization":
      return "den och dess fastigheter samt underhållsplaner permanent raderade.";
    case "app-customer":
      return "den och dess organisationer, fastigheter samt underhållsplaner permanent raderade.";
    case "category":
      return "den raderad och åtgärder kopplade till kategorin kommer att bli okategoriserade men finnas kvar.";
    case "mass_edit":
      return "de och deras eventuella upprepningar permanent raderade.";
    case "maintenance_plan_action":
    default:
      return "den permanent raderad.";
  }
};
