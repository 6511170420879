import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    value: String,
    key: String,
    eventType: String,
  };

  connect() {
    const eventType = this.eventTypeValue || "click";

    this.listener = this.element.addEventListener(eventType, (event) => {
      const form = event.target.closest("form");

      const hidden = document.createElement("input");
      hidden.type = "hidden";
      hidden.name = this.keyValue;
      hidden.value = this.valueValue;
      form.appendChild(hidden);

      form.submit();
    });
  }

  disconnect() {
    this.element.removeEventListener(this.listener);
  }
}
