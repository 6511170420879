const addToProject = () => {
  const modal = window.$("#modal-add-project");
  if (!modal) {
    return;
  }

  modal.on("show.bs.modal", (e) => {
    const actionId = e.relatedTarget.dataset.actionId;
    const projectId = e.relatedTarget.dataset.projectId;
    const dropDown = document.getElementById("project_id").tomselect;

    dropDown.clear();

    document.querySelector(
      "#modal-add-project input[type='hidden'][name='action_id']"
    ).value = actionId;

    if (projectId) {
      dropDown.addItem(projectId);
    }
  });
};

window.addEventListener("DOMContentLoaded", addToProject);
