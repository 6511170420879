import { Controller } from "stimulus";
import debounce from "lodash.debounce";

export default class extends Controller {
  connect() {
    this.reportWindowSize = debounce(this.reportWindowSize, 300).bind(this);
    this.reportWindowSize();
    window.addEventListener("resize", this.reportWindowSize);
  }

  reportWindowSize() {
    if (window.innerWidth < 480) {
      document.querySelectorAll("details").forEach((detail) => {
        detail.removeAttribute("open");
      });
    } else {
      document.querySelectorAll("details").forEach((detail) => {
        detail.setAttribute("open", true);
      });
    }
  }
}
