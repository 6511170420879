import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import t from "../lib/translate";
import API from "../lib/API";
import debounceAsyncFunctionWithCallback from "../lib/async_debounce";

import AsyncSelect from "react-select/async";

const MultipleMaintenancePlanSelect = ({ onChange, ...props }) => {
  const debouncedLoadOptions = useCallback(
    debounceAsyncFunctionWithCallback(loadOptions, 300),
    []
  );

  const [optionCache, setOptionCache] = useState({});
  const hasCachedItem = useCallback(
    Object.prototype.hasOwnProperty.bind(optionCache),
    [optionCache]
  );
  const updateOptionCache = (key, value) => {
    setOptionCache((prevOptionCache) =>
      Object.assign({}, prevOptionCache, {
        [key]: value,
      })
    );
  };

  useEffect(() => {
    props.value
      .filter((key) => !hasCachedItem(key))
      .forEach((key) => loadOptionData(key, updateOptionCache));
  }, [hasCachedItem, props.value]);

  return (
    <AsyncSelect
      {...props}
      isMulti={true}
      placeholder={t("Sök på fastighet, fastighetstagg eller organisation...")}
      onChange={(options) => {
        if (!options) {
          return onChange([]);
        }

        const optionObjects = options.filter(
          (item) => typeof item === "object"
        );
        optionObjects.forEach((option) => {
          updateOptionCache(option.key, option);
        });

        return onChange(getOptionValues(options));
      }}
      isOptionSelected={(item, selected) =>
        getOptionValues(selected).includes(getOptionValue(item))
      }
      loadOptions={debouncedLoadOptions}
      getOptionValue={getOptionValue}
      getOptionLabel={(optionLabelProps) => {
        if (typeof optionLabelProps === "object") {
          return props.getOptionLabel(optionLabelProps);
        }
        if (hasCachedItem(optionLabelProps)) {
          return props.getOptionLabel(optionCache[optionLabelProps]);
        }

        return (
          <span>
            <i className="fa fa-spinner fa-spin" style={{ marginRight: 4 }} />
            {t("Laddar...")}
          </span>
        );
      }}
    />
  );
};

const getOptionValue = (option) =>
  typeof option === "object" ? option.key : option;

const getOptionValues = (options) => options.map(getOptionValue);

const loadOptions = async (input) => {
  const promises = ["organization", "facility", "facility_tag"].map((type) => {
    return getResourcesForType(type, input).then((response) =>
      response.data.map((item) => getOptionFromData(item, type))
    );
  });
  return await Promise.all(promises).then((list) => list.flat());
};

const getOptionFromData = (item, type) => ({
  type,
  label: item.attributes.name,
  key: `${type}-${item.id}`,
});

const loadOptionData = async (key, updateCache) => {
  const [type, id] = key.split("-");
  const res = await API.get(
    `/${pluralizeResourceKey(type)}/${id}`
  ).then((res) => res.json());
  updateCache(key, getOptionFromData(res.data, type));
};

const pluralizeResourceKey = (key) => {
  const pluralMap = {
    facility: "facilities",
    facility_tag: "facility_tags",
    organization: "organizations",
  };

  return pluralMap[key];
};

const getResourcesForType = async (type, query) => {
  return await API.get(`/${pluralizeResourceKey(type)}`, {
    query: {
      page: {
        limit: 10,
      },
      filter: {
        query,
        fields: "name",
      },
      sort: "name",
    },
  }).then((res) => res.json());
};

export default MultipleMaintenancePlanSelect;

MultipleMaintenancePlanSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
};
