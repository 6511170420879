import PageLoader from "./lib/page_loader";
import { qs } from "./lib/helpers";

const INPUT_IDS = [
  "year",
  "previous_year",
  "amount",
  "unit_price",
  "indexation_starts_on_year",
  "maintenance_plan_action_suggestion_id",
  "user_touched_indexation",
];

// Reload form when picking a maintenance plan
window.addEventListener("DOMContentLoaded", () => {
  const maintenancePlanInput = document.querySelector(
    "#maintenance_plan_action_maintenance_plan_id"
  );

  if (!maintenancePlanInput) {
    return;
  }

  maintenancePlanInput.addEventListener("change", (event) =>
    reloadForm(event, "#related-resources")
  );
  maintenancePlanInput.addEventListener("change", (event) =>
    reloadForm(event, "#toggle-indexation")
  );
});

const reloadForm = (event, selector) => {
  const url = buildURL();
  fetch(url)
    .then((res) => res.text())
    .then((html) => {
      PageLoader.updateDOM(html, selector);
    });
};

const buildURL = () => {
  const planId = document.querySelector(
    "#maintenance_plan_action_maintenance_plan_id"
  ).value;
  const queryObject = {
    maintenance_plan_action: {},
  };

  if (planId) {
    queryObject.maintenance_plan_action.maintenance_plan_id = planId;
  }

  const filteredInputIds = getExistingElementIds();
  filteredInputIds.map((id) => {
    queryObject.maintenance_plan_action[id] = document.querySelector(
      `form#action-form #maintenance_plan_action_${id}`
    ).value;
  });

  const path = document.querySelector("[data-total-price-url-value]").dataset
    .totalPriceUrlValue;
  return `${path}?${qs(queryObject)}`;
};

const getExistingElementIds = () => {
  return INPUT_IDS.filter((id) => {
    return document.querySelector(`#maintenance_plan_action_${id}`) != null;
  });
};
