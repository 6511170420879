import React from "react";
import PropTypes from "prop-types";
import t from "../lib/translate";

const SimpleAttachmentsList = ({
  attachments,
  handleDelete,
  fileSizeMB,
  isLoading,
  parentIsClone,
}) => {
  const localeDate = (date) => new Date(date).toLocaleDateString("sv-SE");

  if (!isLoading && parentIsClone && attachments.length == 0) {
    return <p>Det finns inga filer.</p>;
  }

  return attachments.map((attachment) => (
    <div className="flex gap-8 file-item" key={attachment.id}>
      <a href={attachment.url} target="_blank" rel="noopener noreferrer">
        <div style={{ alignSelf: "center" }}>
          <i
            className={`fa fa-file-${attachment.extension} ${attachment.extension}`}
            style={{ fontSize: "24px" }}
          ></i>
        </div>
        <div className="file-name">
          <p>{attachment.name}</p>
          <span className="file-size">{fileSizeMB(attachment)}</span>
          <span className="file-date">
            {`Uppladdad ${localeDate(attachment.created_at)}`}
          </span>
        </div>
      </a>
      {attachment.can_destroy && !parentIsClone && (
        <button
          onClick={() => handleDelete(attachment)}
          className="delete destructive file-delete"
        >
          <i className="fa fa-trash-can"></i> {t("Ta bort")}
        </button>
      )}
    </div>
  ));
};

SimpleAttachmentsList.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
      extension: PropTypes.string.isRequired,
      can_destroy: PropTypes.bool.isRequired,
      user: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        email: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  handleDelete: PropTypes.func,
  fileSizeMB: PropTypes.func,
  isLoading: PropTypes.bool,
  parentIsClone: PropTypes.bool,
};

export default SimpleAttachmentsList;
